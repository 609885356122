<template>
  <div>
    <van-nav-bar title="维修" :z-index="999" :fixed="true"/>

    <!-- <van-list
        style="padding:46px 0px;"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    > -->
    <div style="padding:46px 0px;">
        <div v-for="item in list" :key="item.oid">
            <van-cell-group class="m-t-10 p-10  p-l-5">
                <van-cell :title="'承修：'+item.garageName"
                    style="border-left: 4px solid #2A9F58;margin-left: 5px; height: 30px; line-height: 30px;padding: 0; padding-left: 5px;">
                    <em><span style="color:red;padding-right:10px;">￥{{item.amountReceivable}}</span><del>￥{{item.amountDiscount + item.amountReceivable}}</del></em>
                </van-cell>
            </van-cell-group>
            <van-cell-group>
                <van-cell title="日期" :value="item.orderCreateTime" />
                <van-cell class="views" :to="{name: 'repair_info', params: { id: item.oid }}" value="详情">
                    <template slot="title">
                        <span class="custom-title">项目：</span>
                        <font style="color:#01b185;">{{item.orderItemList ? item.orderItemList[0].itemName : ''}}{{item.orderItemList[1] ? '/'+item.orderItemList[1].itemName : ''}}</font>
                    </template>
                </van-cell>
                <van-cell title="状态">
                    <em v-if="item.status < 6"><span style="color:red;">修理中</span></em>
                    <van-button v-else type="danger" size="small">结算</van-button>
                </van-cell>
            </van-cell-group>
        </div>
    </div>
    <!-- </van-list> -->

    <tabbar :active="2"></tabbar>
  </div>
</template>

<script>
import tabbar from '../../components/tabbar.vue'
import orderModel from '../../api/order'

export default {
  components: {
    tabbar
  },
  data() {
    return {
      loading: false,
      finished: true,
      active:2,
      list: []
    }
  },

  methods: {
    onLoad() {
        const data = {
            carNo: localStorage.getItem('carNo'),
            status: 2
        }

        const loading = this.$toast.loading({
            mask: true,
            duration: 0,
            message: '加载中...'
        });
        orderModel.listHistoryByCustomer(data).then(e => {
            loading.clear()
            this.list = e.data
        }).catch(() => loading.clear())
        
    },
    back() {
        this.$router.go(-1)
    }
  },
  created() {
      this.active = 2
    // this.onLoad()
  }
}
</script>

<style lang="less" scoped>
    .views {
        /deep/ div:nth-of-type(2) span{
            color: #1989fa;
        }
    }
</style>
